// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CoralogixRum } from "@coralogix/browser";
import * as Sentry from "@sentry/nextjs";

import { environment } from "@utils/environment";

const SENTRY_DSN = process.env.SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0,
  enabled: process.env.NODE_ENV === "production",
  release: process.env.CI_COMMIT_SHA,
  environment: environment(),
});

if (environment() === "production") {
  CoralogixRum.init({
    public_key: "cxtp_Tkk45nEZaK0aNoR8CUAiUBRWrlrQ4o",
    application: "api-dash",
    version: `${process.env.CI_COMMIT_REF_NAME}-${process.env.CI_COMMIT_SHA}`,
    coralogixDomain: "US1"
  });
}
