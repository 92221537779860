import { Typography } from "@aviary";

import { Blockquote } from "./Blockquote";
import { CustomCode } from "./CustomCode";
import { CustomPre } from "./CustomPre";
import { InlineCode } from "./InlineCode";
import { Link } from "./Link";
import { ListItem } from "./ListItem";
import { OrderedList } from "./OrderedList";
import { UnorderedList } from "./UnorderedList";

import * as styles from "./MDXComponents.styles";

export const formatID = (id: string) => {
  if (typeof id !== "string") return "";

  return id
    .toLowerCase()
    .replace(/[^a-zA-Z- ]/g, "")
    .replace(/\s/g, "-");
};

const h1 = props => <Typography type="h1" id={formatID(props.children)} {...props} />;
const h2 = props => (
  <Typography css={styles.heading} type="h2" id={formatID(props.children)} {...props} />
);
const h3 = props => (
  <Typography css={styles.subHeading} type="h3" id={formatID(props.children)} {...props} />
);

const h4 = props => (
  <Typography css={styles.subHeading} type="h4" id={formatID(props.children)} {...props} />
);

const p = props => <Typography {...props} />;

const Spacer = () => <br />;

const td = props => <td {...props} css={styles.td} />;

const MDXComponents = {
  h1,
  h2,
  h3,
  h4,
  p,
  td,
  a: Link,
  blockquote: Blockquote,
  hr: Spacer,
  inlineCode: InlineCode,
  li: ListItem,
  ul: UnorderedList,
  ol: OrderedList,
  pre: CustomPre,
  code: CustomCode,
};

export { MDXComponents };
